export const useNativeDriver = true; // false;

export type BackendMode = 'prod' | 'dev' | 'emu';

export const debug = process.env['DEBUG'] == 'true';

export const backendMode = debug ? 'dev' : 'prod';

export const localhost = process.env['NODE_ENV'] == 'development';

export const websiteUrl = localhost
  ? debug
    ? 'http://localhost:3000/'
    : 'http://localhost:3999/'
  : debug
  ? 'https://staging.sociable.bot/'
  : 'https://sociable.bot/';
