import dayjs from 'dayjs';
import hash from 'object-hash';
import { StringTable } from 'shared/lang/StringTable';

export type MeasurementSystem = 'IMPERIAL' | 'METRIC';

export type UnsubscribeT = () => void;

export const appName = 'Sociable';

export interface DataObject {
  id: string;
}

export interface FieldObjectT {
  [id: string]: FieldValueT;
}

export type FieldValueT =
  | string
  | string[]
  | FieldValueT[]
  | number[]
  | number
  | boolean
  | null
  | object
  | FieldObjectT;

export function isDefined<T>(value: T | undefined | null): value is T {
  return (value as T) !== undefined && (value as T) !== null;
}

export function removeNull<T>(value: T | undefined | null): T | undefined {
  if (!isDefined(value)) {
    return undefined;
  }
  return value;
}

function removeUndefinedInternal(x: [string, unknown]): [string, unknown] {
  if (Array.isArray(x[1])) {
    return [x[0], x[1].map(removeUndefinedInternal)];
  } else if (x[1] !== null && typeof x[1] === 'object') {
    return [x[0], removeUndefined(x[1])];
  }
  return x;
}

export function removeUndefined<T extends object>(value: T): T {
  return Object.fromEntries(
    Object.entries(value)
      .filter((x) => x[1] !== undefined)
      .map(removeUndefinedInternal),
  ) as T;
}

export function isStringEmpty(
  value: string | undefined | null,
): value is null | undefined {
  return value === undefined || value === null || value.length == 0;
}

export function sleep(time: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}

export function compact<T>(arr: (T | null | undefined)[]): T[] {
  return arr.filter((x) => isDefined(x));
}

export function stringToString(v: string) {
  return String(v);
}

export function intToString(v: number) {
  return v.toString();
}

export function stringToInt(s: string, strings: StringTable): number {
  const result = Number(s);
  if (isNaN(result)) {
    throw new Error(strings.errorGeneric);
  }
  return result;
}

export function intOptionalToString(v: number | null) {
  if (!isDefined(v)) {
    return '';
  }
  return v.toString();
}

export function stringToIntOptional(
  s: string,
  strings: StringTable,
): number | null {
  if (isStringEmpty(s)) {
    return null;
  }

  const result = Number(s);
  if (isNaN(result)) {
    throw new Error(strings.errorGeneric);
  }
  return result;
}

export function stringToNumber(v: string): number {
  return Number(v);
}

export function numberToString(x: number): string {
  return String(x);
}

export function stringToNumberOptional(v: string): number | null {
  if (isStringEmpty(v)) {
    return null;
  }
  return Number(v);
}

export function numberOptionalToString(x: number | null): string {
  if (!isDefined(x)) {
    return '';
  }
  return String(x);
}

function locationOf<T>(
  array: T[],
  sort: (a: T, b: T) => number,
  element: T,
): [number, boolean] {
  let low = 0,
    high = array.length;

  while (low < high) {
    const mid = (low + high) >>> 1;

    const midElement = array[mid]!;
    const cmp = sort(midElement, element);
    if (cmp == 0) {
      return [mid, true];
    }
    if (cmp < 0) low = mid + 1;
    else high = mid;
  }
  return [low, false];
}

export function insertSorted<T>(
  array: T[] | undefined,
  sort: (a: T, b: T) => number,
  duplicates: 'replace' | 'ignore',
  element: T,
): T[] {
  if (!isDefined(array)) {
    return [element];
  }
  const [index, isExactMatch] = locationOf(array, sort, element);
  if (isExactMatch) {
    if (duplicates == 'ignore') {
      return array;
    } else {
      array[index] = element;
      return array;
    }
  }
  array.splice(index, 0, element);
  return array;
}

export function groupBy<T, K>(
  arr: T[],
  getKey: (i: T) => K,
  sort: (a: T, b: T) => number,
): Map<K, T[]> {
  return arr.reduce((groups, item) => {
    const key = getKey(item);
    groups.set(key, insertSorted(groups.get(key), sort, 'ignore', item));
    return groups;
  }, new Map<K, T[]>());
}

export interface MapObj<K, V> {
  key: K;
  values: V;
}

export function mapToArray<K, V>(
  r: Map<K, V>,
  sort: (a: K, b: K) => number,
): MapObj<K, V>[] {
  const arr: MapObj<K, V>[] = [];
  r.forEach((v: V, k: K) => {
    const a: MapObj<K, V> = {
      key: k,
      values: v,
    };
    insertSorted(
      arr,
      (a: MapObj<K, V>, b: MapObj<K, V>) => {
        return sort(a.key, b.key);
      },
      'ignore',
      a,
    );
  });
  return arr;
}

export function promiseWithTimeout<T>(
  promise: Promise<T>,
  ms: number,
  timeoutError = new Error('Promise timed out'),
): Promise<T> {
  const timeout = new Promise<never>((_, reject) => {
    setTimeout(() => {
      reject(timeoutError);
    }, ms);
  });

  return Promise.race<T>([promise, timeout]);
}

export function delay(cb: () => void, ms: number): UnsubscribeT {
  const timer = setTimeout(cb, ms);
  return () => {
    clearTimeout(timer);
  };
}

export function debouncePromise<A = unknown, R = void>(
  fn: (args: A) => R,
  ms: number,
): [(args: A) => Promise<R>, () => void] {
  let timer: NodeJS.Timeout;

  const debouncedFunc = (args: A): Promise<R> =>
    new Promise((resolve) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        resolve(fn(args));
      }, ms);
    });

  const teardown = () => {
    clearTimeout(timer);
  };

  return [debouncedFunc, teardown];
}

export function compareArrays<T>(arr1: T[], arr2: T[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }

  return (
    arr1.every((value, index) => {
      return value === arr2[index];
    }) &&
    arr1.every((value, index) => {
      return arr2.indexOf(value) === index;
    })
  );
}

export function getZodiacSign(strings: StringTable, birthday: number): string {
  const date = dayjs(birthday);
  const month = date.month() + 1;
  const day = date.date();

  if ((month === 1 && day <= 19) || (month === 12 && day >= 22)) {
    return strings.zodiacCapricorn;
  } else if ((month === 1 && day >= 20) || (month === 2 && day <= 18)) {
    return strings.zodiacAquarius;
  } else if ((month === 2 && day >= 19) || (month === 3 && day <= 20)) {
    return strings.zodiacPisces;
  } else if ((month === 3 && day >= 21) || (month === 4 && day <= 19)) {
    return strings.zodiacAries;
  } else if ((month === 4 && day >= 20) || (month === 5 && day <= 20)) {
    return strings.zodiacTaurus;
  } else if ((month === 5 && day >= 21) || (month === 6 && day <= 20)) {
    return strings.zodiacGemini;
  } else if ((month === 6 && day >= 21) || (month === 7 && day <= 22)) {
    return strings.zodiacCancer;
  } else if ((month === 7 && day >= 23) || (month === 8 && day <= 22)) {
    return strings.zodiacLeo;
  } else if ((month === 8 && day >= 23) || (month === 9 && day <= 22)) {
    return strings.zodiacVirgo;
  } else if ((month === 9 && day >= 23) || (month === 10 && day <= 22)) {
    return strings.zodiacLibra;
  } else if ((month === 10 && day >= 23) || (month === 11 && day <= 21)) {
    return strings.zodiacScorpio;
  } else {
    return strings.zodiacSagittarius;
  }
}

export function compareObjects<T extends hash.NotUndefined>(
  obj1: T,
  obj2: T,
): boolean {
  const hash1 = hash(obj1);
  const hash2 = hash(obj2);
  return hash1 === hash2;
}

export function convertMetersToFeetInchesString(
  _strings: StringTable,
  meters: number,
  longVersion: boolean,
): string {
  const totalInches = Math.round((meters * 100) / 2.54);
  const feet = Math.floor(totalInches / 12);
  const inches = totalInches % 12;
  if (longVersion) {
    if (inches > 0) {
      return `${feet} feet ${inches} inches`;
    } else {
      return `${feet} feet`;
    }
  }
  return `${feet}' ${inches}"`;
}

export function convertMetersToCMString(
  strings: StringTable,
  meters: number,
): string {
  const cm = meters * 100;
  return `${Math.round(cm)} ${strings.cm}`;
}

export function convertMetersToHeightUnitString(
  strings: StringTable,
  units: MeasurementSystem | undefined | null,
  meters: number,
): string {
  switch (units ?? 'IMPERIAL') {
    case 'IMPERIAL':
      return convertMetersToFeetInchesString(strings, meters, false);
    case 'METRIC':
      return convertMetersToCMString(strings, meters);
  }
}

export function convertMetersToMileString(
  strings: StringTable,
  meters: number,
): string {
  const miles = meters * 0.0006213712;
  if (miles < 5) {
    return strings.withinFiveMiles;
  }
  return `${miles.toFixed()} ${strings.milesShort}`;
}

export function convertMetersToKMString(
  strings: StringTable,
  meters: number,
): string {
  const km = meters / 1000;
  if (km < 5) {
    return strings.withinFiveKm;
  }
  return `${km.toFixed()} ${strings.kmShort}`;
}

export function convertMetersToDistanceUnitString(
  strings: StringTable,
  units: MeasurementSystem | undefined | null,
  meters: number,
): string {
  switch (units ?? 'IMPERIAL') {
    case 'IMPERIAL':
      return convertMetersToMileString(strings, meters);
    case 'METRIC':
      return convertMetersToKMString(strings, meters);
  }
}

export function removeDuplicates<T>(
  getId: (t: T) => string,
  items: T[],
  order?: 'lastWins' | 'firstWins',
): T[] {
  const seenIds = new Set<string>();
  if (order == 'lastWins') {
    return items
      .reverse()
      .filter((item) => {
        const id = getId(item);
        if (seenIds.has(id)) {
          return false;
        } else {
          seenIds.add(id);
          return true;
        }
      })
      .reverse();
  }
  return items.filter((item) => {
    const id = getId(item);
    if (seenIds.has(id)) {
      return false;
    } else {
      seenIds.add(id);
      return true;
    }
  });
}

export function updateItems<T extends DataObject>(
  oldItems: T[],
  newItems: T[],
  sort: (a: T, b: T) => number,
) {
  // Remove updated Items, add new items, resort
  const newIds = new Set(newItems.map((x) => x.id));
  return [...oldItems.filter((x) => !newIds.has(x.id)), ...newItems].sort(sort);
}

export function pickRandom<T>(x: T[]): T {
  return x[(Math.random() * x.length) | 0]!;
}

export function numericToAlphabet(numericIndex: number): string {
  // Convert numeric index to alphabet (A=1, B=2, C=3, ...)
  return String.fromCharCode(65 + numericIndex - 1);
}

export function capitalizeFirstLetter(input: string): string {
  if (input.length === 0) {
    return input;
  }
  return input.charAt(0).toUpperCase() + input.slice(1);
}

export function getDayName(index: number): string {
  if (index < 0 || index > 6) {
    return 'Sunday';
  }

  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  return days[index] ?? 'Sunday';
}

export function getEnumFromString<T>(
  strValue: string | null | undefined,
  validValues: Set<T>,
  defaultValue: T,
): T {
  if (isStringEmpty(strValue)) {
    return defaultValue;
  }
  const tValue = strValue as T;
  if (!validValues.has(tValue)) {
    return defaultValue;
  }
  return tValue;
}

export function shuffle<T>(array: T[]): T[] {
  return array.sort(() => Math.random() - 0.5);
}

export async function retry<T>(
  cb: () => Promise<T>,
  limit: number,
): Promise<T> {
  let attempt = 0;
  do {
    try {
      return await cb();
    } catch (error) {
      attempt++;
      if (attempt >= limit) {
        throw error;
      }
    }
    // eslint-disable-next-line no-constant-condition, @typescript-eslint/no-unnecessary-condition
  } while (true);
}

export function chunkArray<T>(array: T[], chunkSize: number): T[][] {
  const resultArray: T[][] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    resultArray.push(chunk);
  }
  return resultArray;
}

export function stringToHash(x: string) {
  let hash = 0;

  if (x.length == 0) return hash;

  for (let i = 0; i < x.length; i++) {
    const char = x.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }

  return hash;
}

let time = Date.now();

export function perfStart() {
  time = Date.now();
}

export function perfMarker(x: string) {
  const newTime = Date.now();
  console.log(`[PERF] ${x} ${newTime - time}`);
  time = newTime;
}

export function isPromise<T>(obj: unknown): obj is Promise<T> {
  const x = obj as Promise<T>;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return x !== null && typeof x.then === 'function';
}

export interface Base64ImagesPart {
  mimeType: 'image/jpeg' | 'image/png';
  base64: string;
}

export function base64UriToParts(dataUrl: string): Base64ImagesPart {
  const parts = dataUrl.split(';base64,');

  if (parts.length !== 2 || !parts[0]!.startsWith('data:')) {
    throw new Error('Invalid data URL');
  }

  const mimeType = parts[0]!.slice(5);
  const base64 = parts[1]!;

  const result: Base64ImagesPart = {
    mimeType: mimeType == 'image/png' ? 'image/png' : 'image/jpeg',
    base64,
  };

  return result;
}

export function base64PartsToUri(parts: Base64ImagesPart) {
  return `data:${parts.mimeType};base64,${parts.base64}`;
}

export async function blobToBase64(input: Blob) {
  const buffer = Buffer.from(await input.arrayBuffer());
  return buffer.toString('base64');
}

export function slotToString(x: number | null) {
  if (!isDefined(x)) {
    return 'Not set';
  }

  const hourVal = Math.floor(x / 60);
  const minVal = x % 60;
  const min = String(minVal).padStart(2, '0');
  let hour: string;
  let am: boolean;
  if (hourVal == 0) {
    hour = '12';
    am = true;
  } else if (hourVal < 12) {
    hour = String(hourVal);
    am = true;
  } else if (hourVal == 12) {
    hour = '12';
    am = false;
  } else {
    hour = String(hourVal - 12);
    am = false;
  }
  return `${hour}:${min} ${am ? 'am' : 'pm'}`;
}
