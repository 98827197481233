import { logCustomEvent } from 'client/common/provider/Analytics';
import { call, request } from 'client/frontend/Socket';
import { DocResult, getDoc, getDocs, trackDoc } from 'client/frontend/Store';
import { ErrorOutput } from 'shared/Functions';
import { UnsubscribeT } from 'shared/Helper';
import {
  UserDeepgramKeyOutput,
  UserDeleteInput,
  UserEmailLoginInput,
  UserEmailResetInput,
  UserEmailSetEmailInput,
  UserEmailUnsubscribeInput,
  UserEmailVerifyInput,
  UserGetByEmailInput,
  UserGetByEmailOutput,
  UserGetByPhoneInput,
  UserGetByPhoneOutput,
  UserGetOrCreateInput,
  UserGetPhoneInput,
  UserGetPhoneOutput,
  UserGoogleLoginInput,
  UserLoadInput,
  UserLoadOutput,
  UserPublic,
  UserSendVerificationCodeInput,
  UserSetFieldsInput,
  UserSetPasswordInput,
  UserSetPhoneInput,
  UserVerifyCodeInput,
  UserVerifyCodeOutput,
} from 'shared/UserPublic';
import { Socket } from 'socket.io-client';

export async function userGetByPhone(
  socket: Socket,
  input: UserGetByPhoneInput,
): Promise<UserGetByPhoneOutput> {
  return await call(socket, 'userGetByPhone', input);
}

export async function userGetByEmail(
  socket: Socket,
  input: UserGetByEmailInput,
): Promise<UserGetByEmailOutput> {
  return await call(socket, 'userGetByEmail', input);
}

export async function userGetOrCreate(
  socket: Socket,
  input: UserGetOrCreateInput,
): Promise<ErrorOutput> {
  return await call(socket, 'userGetOrCreate', input);
}

export async function userDelete(
  socket: Socket,
  input: UserDeleteInput,
): Promise<ErrorOutput> {
  return await call(socket, 'userDelete', input);
}

export function userGet(
  socket: Socket,
  id: string,
): Promise<UserPublic | null> {
  return getDoc<UserPublic>(socket, { collection: 'userPublic', id });
}

export function userTrack(
  socket: Socket | null,
  id: string,
  cb: DocResult<UserPublic>,
): UnsubscribeT {
  return trackDoc<UserPublic>(socket, { collection: 'userPublic', id }, cb);
}

export async function userGetPhone(
  socket: Socket,
  input: UserGetPhoneInput,
): Promise<UserGetPhoneOutput> {
  return await call(socket, 'userGetPhone', input);
}

export async function userSendVerificationCode(
  input: UserSendVerificationCodeInput,
): Promise<ErrorOutput> {
  return await request('userSendVerificationCode', input);
}

export async function userVerifyCode(
  input: UserVerifyCodeInput,
): Promise<ErrorOutput> {
  const result = await request<UserVerifyCodeInput, UserVerifyCodeOutput>(
    'userVerifyCode',
    input,
  );
  return result;
}

export async function userLoad(
  socket: Socket,
  input: UserLoadInput,
): Promise<UserLoadOutput> {
  return await call(socket, 'userLoad', input);
}

export async function userGoogleLogin(
  input: UserGoogleLoginInput,
): Promise<UserVerifyCodeOutput> {
  return await request('userGoogleLogin', input);
}

export async function userEmailLogin(
  input: UserEmailLoginInput,
): Promise<UserVerifyCodeOutput> {
  return await request('userEmailLogin', input);
}

export async function userSetPassword(
  socket: Socket,
  input: UserSetPasswordInput,
): Promise<ErrorOutput> {
  return await call(socket, 'userSetPassword', input);
}

export async function userEmailReset(
  input: UserEmailResetInput,
): Promise<ErrorOutput> {
  return await request('userEmailReset', input);
}

export async function userEmailSetEmail(
  socket: Socket,
  input: UserEmailSetEmailInput,
): Promise<ErrorOutput> {
  return await call(socket, 'userEmailSetEmail', input);
}

export async function userSetFields(
  socket: Socket,
  input: UserSetFieldsInput,
): Promise<ErrorOutput> {
  Object.entries(input.fields).forEach((field) => {
    logCustomEvent('USER_SET_' + field[0].toUpperCase(), {
      value: String(field[1]),
    });
  });

  return await call(socket, 'userSetFields', input);
}

export async function userEmailVerify(
  input: UserEmailVerifyInput,
): Promise<UserVerifyCodeOutput> {
  return await request('userEmailVerify', input);
}

export async function userAnonymousLogin(): Promise<UserVerifyCodeOutput> {
  return await request('userAnonymousLogin', {});
}

export async function userEmailUnsubscribe(
  input: UserEmailUnsubscribeInput,
): Promise<ErrorOutput> {
  return await request('userEmailUnsubscribe', input);
}

export async function userDeepgramKey(
  socket: Socket,
): Promise<UserDeepgramKeyOutput> {
  return await call(socket, 'userDeepgramKey', {});
}

export async function userSetPhone(
  socket: Socket,
  input: UserSetPhoneInput,
): Promise<ErrorOutput> {
  return await call(socket, 'userSetPhone', input);
}

export const userImageAspectRatio = 1;

export async function userGetBatch(
  socket: Socket | null,
  ids: string[],
): Promise<UserPublic[]> {
  return await getDocs<UserPublic>(socket, {
    collection: 'userPublic',
    where: [{ fieldPath: 'id', opStr: 'in', value: ids }],
  });
}
